import React from "react";
import { useSelector } from 'react-redux'
import { BannerSelector } from '../../store/reducers/BannerReducers'
import "../HeaderPage/HeaderPage.css";
import { useLocation } from 'react-router-dom'
import { Banner } from '../../models/reducers/IBanner'

interface CustomInputProps {
	children: React.ReactNode
	index: string
  }
function HeaderPage({ children, index }: CustomInputProps) {
	const id_page = 'HeaderPage';
	let location = useLocation();
	const ParentPage = location.pathname.replace("/", "").replace("/", "");
	const todosSelector = useSelector(BannerSelector)

	let todosLoading: Banner[]|undefined = todosSelector.allPageLoad

	todosLoading = todosLoading?.filter(todo => todo.index === index && todo.id_page === id_page && todo.pathtype === ParentPage)
	return (
		<>
			{
				todosLoading?.map((todo) => (
					<div key={todo.subtitle_01} className="page-title-area" style={{ backgroundImage: `url(${todo.background})` }}>
						<div className="d-table">
							<div className="d-table-cell">
								<div className="container">
									<div className="page-title-content">
										<h2>{todo.title_01}</h2>
										<ul>
											<li><a href="/">Trang chủ</a></li>
											<li>{todo.subtitle_01}</li>
										</ul>
									</div>
								</div>
							</div>
						</div>
					</div>
				))
			}
		</>

	);
}
export default HeaderPage

