import React from "react";
import { useLocation } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { BannerSelector,findprojectname } from '../../store/reducers/BannerReducers'
import ProjectList from "./ProjectList"
import "../../component/Project/Project.css"
import { Banner } from '../../models/reducers/IBanner'

interface CustomInputProps {
  children: React.ReactNode
  index: string
}

function Project ({ children, index }: CustomInputProps) {
    const id_page = 'Project';
    let location = useLocation();
	const ParentPage = location.pathname.replace("/", "").replace("/", "");
	const todosSelector = useSelector(BannerSelector)

	let todosLoading: Banner[]|undefined = todosSelector.allPageLoad
	todosLoading = todosLoading?.filter(todo => todo.index === index && todo.id_page === id_page && todo.pathtype === ParentPage)
	const project_name = todosSelector.project_name


	

    const dispatch = useDispatch()

    const choosePost = (todotab: string) => {
        dispatch(findprojectname(todotab))
    }
    
  return (
    <>
    <section className="project-area section-padding">
        <div className="container">
			<div className="row">
				<div className="col-lg-12 col-md-12">
					<div className="section-title">
						<h6>Recent Works</h6>
						<h2>Our Portfolio</h2>
					</div>
				</div>
			</div>
            <div className="row">
                <div className="col-md-12">
                    <div className="project-list">
                       <ul className="nav">
                            {todosLoading?.map((todo, index) => {
                                return(
                                    <li id={todo.title_01} onClick={() => choosePost(todo.title_01)} key={index} className={project_name === todo.title_01 ? 'filter filter-active' : 'filter'} data-group={todo.title_01}>{todo.title_01}</li>
                                )
                            })}
                       </ul>
                    </div>
        
                    <div className="project-container">
                    <ProjectList ParentPage={ParentPage} index={"1"}>
                        
                    </ProjectList>
                    </div>
            </div>
        </div>
        </div>
    </section>
    </>
  );
}

export default Project;
