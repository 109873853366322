import React, {useEffect} from "react";
import { useSelector, useDispatch } from 'react-redux'
import { BannerSelector } from '../../store/reducers/BannerReducers'
import {Banner} from '../../models/reducers/IBanner'

interface CustomInputProps {
  children: React.ReactNode
  index: string
  ParentPage: string
}

function ContactInformationList({ children, index , ParentPage }: CustomInputProps) {
  const id_page = 'ContactInformationList';
  const todosSelector = useSelector(BannerSelector)

  let todosLoading: Banner[]|undefined = todosSelector.allPageLoad
  todosLoading = todosLoading?.filter(todo => todo.index === index && todo.id_page === id_page && todo.pathtype === ParentPage)

  return (
    <>
    {todosLoading?.map((todo, index) => {
        return (
            <div key={index} className="col-lg-4 col-md-6">
                <div className="contact-info-content">
						<h5>{todo.title_01}</h5>
						<p>{todo.subtitle_01}</p>
						<a href="tel:${todo.phone}" >{todo.subfooter_01}</a>
						<a href="mailto:${todo.email}">{todo.subfooter_02}</a>
					</div>
            </div>
        );
    })}
    </>
  );
}

export default ContactInformationList;


