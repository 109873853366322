import React from "react";
import FooterLink from "./FooterLink"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTwitter, faFacebookF, faLinkedin, faPinterest } from '@fortawesome/free-brands-svg-icons/index'
import { faEnvelope, faPhoneVolume, faLocationDot } from '@fortawesome/free-solid-svg-icons';

function FooterArea() {
    const footer_heading1 = "GIỚI THIỆU";
    const footer_heading2 = "HỆ THỐNG NHÀ MÁY";
    const footer_heading3 = "Liên kết hữu ích";
    const footer_heading4 = "THÔNG TIN LIÊN LẠC";
    const footer_content = "Với khẩu hiệu “Green planet & Green future”, Công ty TNHH XNK Năng lượng Đại Dương mang trong mình sứ mệnh cung cấp nguồn năng lượng xanh, sạch cho cuộc sống";
    const footer_textphone = "Điện thoại";
    const footer_textemail = "Email";
    const footer_phone = "028 3622 0259";
    const footer_mail = "info@daiduongenergy.com";
    const footer_address = "Tầng 12, Tòa Nhà TNR, Số 180-192 Nguyễn Công Trứ, Phường Nguyễn Thái Bình, Quận 1, Tp.Hồ Chí Minh";
    const footer_textaddress = "Địa chỉ";


    return (
        <div className="footer-area ptb-100">
            <div className="container">
                <div className="row">
                    <div className="col-lg-3 col-md-6 col-sm-6">
                        <div className="single-footer-widget">
                            <img className="white-logo logo" src="/assets/Logo3-removebg-preview.png" alt="logo" />
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-6">
                        <div className="single-footer-widget">
                            <div className="footer-heading">
                                <h3>{footer_heading1}</h3>
                            </div>
                            <p>{footer_content}</p>
                            {/* <ul className="footer-social">
                                <li>
                                    <a href="https://www.facebook.com"> <FontAwesomeIcon icon={faFacebookF} />
                                    </a>
                                </li>
                                <li>
                                    <a href="#"><FontAwesomeIcon icon={faTwitter} />
                                    </a>
                                </li>
                                <li>
                                    <a href="#"> <FontAwesomeIcon icon={faPinterest} />
                                    </a>
                                </li>
                                <li>
                                    <a href="#"><FontAwesomeIcon icon={faLinkedin} />
                                    </a>
                                </li>
                            </ul> */}
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-6">
                        <div className="single-footer-widget">
                            <div className="footer-heading">
                                <h3>{footer_heading2}</h3>
                            </div>
                            <ul className="footer-quick-links">
                                <FooterLink value={1}>

                                </FooterLink>

                            </ul>
                        </div>
                    </div>
                    {/* <div className="col-lg-2 col-md-6 col-sm-6">
                        <div className="single-footer-widget">
                            <div className="footer-heading">
                                <h3>{footer_heading3}</h3>
                            </div>
                            <ul className="footer-quick-links">
                                <FooterLink value = {2}>
                                    
                                </FooterLink>

                            </ul>
                        </div>
                    </div> */}
                    <div className="col-lg-3 col-md-6 col-sm-6">
                        <div className="single-footer-widget">
                            <div className="footer-heading">
                                <h3>{footer_heading4}</h3>
                            </div>
                            <div className="footer-info-contact"><FontAwesomeIcon icon={faPhoneVolume} />
                                <h3>{footer_textphone}</h3>
                                <span><a href="tel:`${footer_phone}`">{footer_phone}</a></span>
                            </div>
                            <div className="footer-info-contact"> <FontAwesomeIcon icon={faEnvelope} />
                                <h3>{footer_textemail}</h3>
                                <span><a href="mailto:`${footer_mail}`">{footer_mail}</a></span>
                            </div>
                            <div className="footer-info-contact"><FontAwesomeIcon icon={faLocationDot} />
                                <h3>{footer_textaddress}</h3>
                                <span>{footer_address}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default FooterArea;



