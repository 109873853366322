import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { collection, doc, getDocs } from "firebase/firestore";
import db from '../../config/firebase'
import type { RootState } from '../store'
import { Banner } from '../../models/reducers/IBanner'
import { apiUrl, dbType } from '../../config/constants'
import axios from 'axios'

// Reducer Thunk
export const getBanner = createAsyncThunk<Banner[]>('Banner/todosFetched', async () => {
    try {
        let response
        let docList
        if (dbType === "FireBase") {
            response = collection(db, "Banner_Vi");
            const docSnapshot = await getDocs(response);
            docList = docSnapshot.docs.map(doc => doc.data());
        }
        else {
            response = await axios.get(`${apiUrl}/banner`)
            if (response.data.success) {
                docList = response.data.Banner
            }
            else {
                console.log(response.data)
            }
        }
        console.log(docList)
        return (docList) as Banner[]
    }
    catch (error) {
        console.log(error)
        return []
    }

})

interface PagesState {
    allPageLoad?: Banner[];
    isLoading: boolean;
    project_name: string;
}

const initialState: PagesState = {
    allPageLoad: [],
    isLoading: true,
    project_name: 'all'
}

const BannerSlice = createSlice({
    name: 'Banner',
    initialState,
    reducers: {
        findprojectname(state, action) {
            const postID = action.payload
            state.project_name = postID
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getBanner.pending, (state, action) => {
            console.log('Fetching todos from backend ....')
            state.isLoading = true
        })
        builder.addCase(getBanner.fulfilled, (state, action) => {
            console.log('Done')
            state.allPageLoad = action.payload
            state.isLoading = false
        })
        builder.addCase(getBanner.rejected, (state, action) => {
            console.log('Failed to get todos!!!')
            state.isLoading = false
        })
    }
})

// Reducer
const BannerReducer = BannerSlice.reducer

// Selector
export const BannerSelector = (state: RootState) => state.Banner
// Action export
export const {
    findprojectname
    // deleteTodo
    // todosFetched
} = BannerSlice.actions

// Export reducer
export default BannerReducer