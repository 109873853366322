import React from "react";
import { useSelector } from 'react-redux'
import { BannerSelector } from '../../store/reducers/BannerReducers'

import { Banner } from '../../models/reducers/IBanner'
import Slider from 'react-slick';

interface CustomInputProps {
	children: React.ReactNode
	index: string
    ParentPage: string
  }

function ServicesDetailListImg({ children, index , ParentPage }: CustomInputProps) {
    
    const id_page = 'ServicesDetailListImg';

    const todosSelector = useSelector(BannerSelector)

    let todosLoading: Banner[]|undefined = todosSelector.allPageLoad
    console.log(index,todosLoading?.filter(todo => todo.index === index &&  todo.pathtype === ParentPage))

    todosLoading = todosLoading?.filter(todo => todo.index === index && todo.id_page === id_page && todo.pathtype === ParentPage)

    const settings = {
        className: 'image-sliders',
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: false,
        arrows: true,
        infinite: true,
        speed: 500,
        autoplay:true,
        responsive: [{
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }]
      };
  return (
      <>
        <Slider {...settings}>
            {todosLoading?.map((todo, index) => {
                return (
                    <div key={index} className="services-details-image">
                        <img src={todo.image_url_01} alt="image" />
                    </div>
                );
            })}
        </Slider>
    </>
  );
}

export default ServicesDetailListImg;


