import React from "react";
import { useSelector } from 'react-redux'
import { BannerSelector } from '../../store/reducers/BannerReducers'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUserAlt, faCalendarWeek } from '@fortawesome/free-solid-svg-icons';
import {Banner} from '../../models/reducers/IBanner'

interface CustomInputProps {
  children: React.ReactNode
  index: string
  ParentPage: string
}

function BlogList({ children, index , ParentPage }: CustomInputProps) {

	const id_page = 'BlogList'; 
    const todosSelector = useSelector(BannerSelector)

    let todosLoading: Banner[]|undefined = todosSelector.allPageLoad
    todosLoading = todosLoading?.filter(todo => todo.index === index && todo.id_page === id_page && todo.pathtype === ParentPage)

  return (
    <>
    {todosLoading?.map((todo, index) => {
        return (
            <div key={index} className="col-lg-4 col-md-6">
                <div className="blog-item">
                    <div className="blog-image">
                        <a href="single-blog.html">
                            <img src={todo.image_url_01} alt={todo.title_01}/>
                        </a>
                    </div>
                    <div className="single-blog-item">
                        <ul className="blog-list">
                            <li>
                                <a href="#"> <FontAwesomeIcon icon={faUserAlt} /> Author</a>
                            </li>
                            <li>
                                <a href="#"> <FontAwesomeIcon icon={faCalendarWeek} /> 17 June 2021</a>
                            </li>
                        </ul>
                        <div className="blog-content">
                            <h3>
                                <a href="single-blog.html">
                                    {todo.title_01}
                                </a>
                            </h3>
                            <p>{todo.content_01}</p>
                            <div className="blog-btn"> <a href={todo.link_01} className="blog-btn-one">{todo.button_01}</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    })}
    </>
  );
}

export default BlogList;


