import React from "react";
import HeaderPage from "../../component/HeaderPage/HeaderPage";
import ServicesDetail from "../../component/Services/ServicesDetai";


function ServicesDetailPage() {

    let body

    body = (
        <>
            <HeaderPage index={"1"}>

            </HeaderPage>
            <section className="services-details-area ptb-100">
                <div className="container">
                    <div className="services-details-overview">
                        <div className="row align-items-center">
                            <ServicesDetail index={"1"}>

                            </ServicesDetail>
                            <ServicesDetail index={"2"}>
                                
                            </ServicesDetail>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
    return (
        <>
            {body}
        </>
    )
}

export default ServicesDetailPage;