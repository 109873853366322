import React from "react";
import { useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import {Banner} from '../../models/reducers/IBanner'
import { BannerSelector } from '../../store/reducers/BannerReducers'

interface CustomInputProps {
    children: React.ReactNode
    index: string
  }
function Map({ children, index }: CustomInputProps) {
  const id_page = 'Map';
	let location = useLocation();
  const ParentPage = location.pathname.replace("/", "").replace("/", "");

  const todosSelector= useSelector(BannerSelector)
  let todosLoading: Banner[]|undefined = todosSelector.allPageLoad
  todosLoading = todosLoading?.filter(todo => todo.index === index && todo.id_page === id_page && todo.pathtype === ParentPage)
  return (
	  <>
     {todosLoading?.map(todo => (
    <div key={index} className="map-area">
        <div className="map-content">
            <iframe
              src={todo.content_01}
              width="100%"
              height="450"
              frameBorder="0"
              style={{ border: 0 }}
              allowFullScreen={false}
              aria-hidden="false"
              tabIndex={0}
            />
        </div>
    </div>
     ))}
	</>
  );
}

export default Map;