import React from "react";
import { useSelector } from 'react-redux'
import { BannerSelector } from '../../store/reducers/BannerReducers'
import BlogList from "./BlogList"
import "../Blog/Blog.css"
import { useLocation } from 'react-router-dom'
import { Banner } from '../../models/reducers/IBanner'

interface CustomInputProps {
  children: React.ReactNode
  index: string
}

function Blog({ children, index }: CustomInputProps) {

	const id_page = 'Blog';
	let location = useLocation();
	const ParentPage = location.pathname.replace("/", "").replace("/", "");
	const todosSelector = useSelector(BannerSelector)

	let todosLoading: Banner[]|undefined = todosSelector.allPageLoad
	todosLoading = todosLoading?.filter(todo => todo.index === index && todo.id_page === id_page && todo.pathtype === ParentPage)
	return (
		<>

			<section className="blog-section bg-grey pt-100 pb-70">
				<div className="container">
					<div className="section-title">
						{todosLoading?.map((todo, index) => {
							return (
								<div key={index}>
									<h6>{todo.title_01}</h6>
									<h2>{todo.subtitle_01}</h2>
								</div>
							)
						})}
					</div>
					<div className="row">
						<BlogList ParentPage={ParentPage} index={"1"}>
							
						</BlogList>
					</div>
				</div>
			</section>

		</>
	);
}

export default Blog;