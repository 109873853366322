import { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';
import { menuItem } from '../../models/layouts/Navigation/Imenu'
import Dropdown from "./Dropdown"

function SiteNav() {



    const menuItems: menuItem[] = [
        // ...
        {
            title: "Trang chủ",
            link: "/",
        },
        {
            title: "Giới thiệu",
            link: "/about"
        },
        {
            title: "Sản phẩm",
            link: "/services",
            // submenu: [
            //     {
            //         title: "Bằng bao Jumbo",
            //         link: "/services"
            //     },
            //     {
            //         title: "Bằng container",
            //         link: "/services-detail"
            //     },
            //     {
            //         title: "Bằng tàu rời",
            //         link: "/services-detail-2"
            //     }
            // ]
        },
        // {
        //     title: "Hệ thống nhà máy ",
        //     link: "",
        //     // submenu: [
        //     //     {
        //     //         title: "Trải nghiệm khách hàng ",
        //     //         link: "/project-detail"
        //     //     }
        //     // ]
        // },
        {
            title: "Liên hệ",
            link: "/contact"
        },
        // ...
    ];

    const [showDropdown, setShowDropdown] = useState(false)
    const [title, setTitle] = useState("")

    useEffect(() => {
        if (showDropdown !== false) {
        }
    }, [showDropdown])

    function handleRun(TitleDrop: string) {
        setTitle(TitleDrop)
        var element = document.getElementsByClassName('nav-item-sub');
        // Printing the element
        if (window.innerWidth < 991) {
            if (showDropdown === true && element) {
                setShowDropdown(showDropdown => !showDropdown)
            } else {
                setShowDropdown(showDropdown => !showDropdown)

            }
        }
    }

    return (
        <>
            <ul className="navbar-nav">
                {menuItems.map((items, index) => {
                    return (
                        <li key={index} className={`nav-item nav-item-sub ${index} ${showDropdown && items.title === title ? ('active') : ('')}`} onClick={() => handleRun(items.title)}>
                            {items.submenu ? (
                                <>
                                    <a href="#" className={`nav-link `} type="button" aria-haspopup="menu" >
                                        {items.title}{" "}
                                        <FontAwesomeIcon icon={faAngleDown} />
                                    </a>
                                    <Dropdown value={items.submenu} >
                                    </Dropdown>
                                    {showDropdown && items.title === title ? (
                                        <a className="mean-expand mean-clicked" href="#" style={{ fontSize: 18 }}>-</a>
                                    ) : (<a className="mean-expand" href="#" style={{ fontSize: 18 }}>+</a>)}

                                </>
                            ) : (
                                <NavLink to={items.link} className="nav-link">{items.title}</NavLink>
                            )}
                        </li>
                    )
                })}
            </ul>
        </>
    );
}

export default SiteNav;


