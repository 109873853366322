import React from "react";
import HeaderPage from "../../component/HeaderPage/HeaderPage";
import About from "../../component/About/About";
import Counter from "../../component/Counter/Counter";
import Team from "../../component/Team/Team";
import Testimonial from "../../component/Testimonial/Testimonial";
import HeroBanners from "../../component/Hero-banner/Hero-banner";
import Brand from "../../component/Brand/Brand";


function AboutPage() {

    let body

    body = (
        <>
            <HeaderPage index={"1"} >

            </HeaderPage>
            <About index={"1"} >

            </About>
            <Counter index={"1"} >

            </Counter>
            <Team index={"1"} >

            </Team>
            <Testimonial index={"1"}>

            </Testimonial>
            {/* <HeroBanners index={"2"}>

            </HeroBanners> */}
            {/* <Brand index={"1"}>
                
            </Brand> */}
        </>
    )

    return (
        <>
            {body}
        </>
    )
}

export default AboutPage;