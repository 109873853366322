import React from "react";
import { useSelector } from 'react-redux'
import { BannerSelector } from '../../store/reducers/BannerReducers'
import "../Services/Service.css"
import {Banner} from '../../models/reducers/IBanner'

interface CustomInputProps {
  children: React.ReactNode
  index: string
  ParentPage: string
}

function ProjectList({ children, index , ParentPage }: CustomInputProps) {
  const id_page = 'ProjectList';

  const todosSelector = useSelector(BannerSelector)
  
  let todosLoading: Banner[]|undefined = todosSelector.allPageLoad
  todosLoading = todosLoading?.filter(todo => todo.index === index && todo.id_page === id_page && todo.pathtype === ParentPage && todo.content_02.includes(todosSelector.project_name))
   
  return (
      <>
       
    {todosLoading?.map((todo, index) => (
       <div key={index} className={`col-6 col-md-4 project-grid-item ${todo.content_02}`}>
       <div className="project-item">
           <img src={todo.image_url_01} alt="image" />
           <div className="project-content-overlay">
               <p>{todo.title_01}</p>
               <h3><a href="single-projects.html">{todo.content_01}</a></h3>
           </div>
       </div>
        </div>
    ))}
    </>
  );
}

export default ProjectList;


