import React from "react";
import ServicesList from "../Services/ServicesList";
import { useSelector } from 'react-redux'
import { BannerSelector } from '../../store/reducers/BannerReducers'
import "../Services/Service.css"
import { useLocation } from 'react-router-dom'
import {Banner} from '../../models/reducers/IBanner'

interface CustomInputProps {
  children: React.ReactNode
  index: string
}
function Services({ children, index }: CustomInputProps) {
  const id_page = 'Services';
  let location = useLocation();
  const ParentPage = location.pathname.replace("/", "").replace("/", "");
  const todosSelector = useSelector(BannerSelector)

  let todosLoading: Banner[]|undefined = todosSelector.allPageLoad
  todosLoading = todosLoading?.filter(todo => todo.index === index && todo.id_page === id_page && todo.pathtype === ParentPage)

  return (
    <>

      <section className="services-section section-padding">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              {todosLoading?.map((todo) => {
                return (
                  <div key={todo.title_01} className="section-title">
                    <h6>{todo.title_01}</h6>
                    <h2>{todo.subtitle_01}</h2>
                  </div>
                )
              })}
            </div>
          </div>
          <div className="row">
            <ServicesList ParentPage={ParentPage} index={"1"}>
              
            </ServicesList>
          </div>
        </div>
      </section>

    </>
  );
}

export default Services;
