import React from "react";
import { useSelector } from 'react-redux'
import { BannerSelector } from '../../store/reducers/BannerReducers'
import "../Services/Service.css"
import parse from 'html-react-parser';
import { Banner } from '../../models/reducers/IBanner'

interface CustomInputProps {
  children: React.ReactNode
  index: string
  ParentPage: string
}
function ServicesList({ children, index, ParentPage }: CustomInputProps) {
  const id_page = 'ServicesList';
  const todosSelector = useSelector(BannerSelector)

  let todosLoading: Banner[] | undefined = todosSelector.allPageLoad
  todosLoading = todosLoading?.filter(todo => todo.index === index && todo.id_page === id_page && todo.pathtype === ParentPage)
  return (
    <>
      {todosLoading?.map(todo => (
        <div key={todo.title_01} className="col-lg-4 col-md-6">
          <div className="single-services-item">
            <div className="services-icon">
              <img src={todo.image_url_01} alt={todo.title_01} />
            </div>
            <h3>{todo.title_01}</h3>
            <p>{parse(todo.content_01)}</p>
            <div className="services-btn-link">
              <a href={todo.link_01} className="services-link">{parse(todo.button_01)}</a>
            </div>
          </div>
        </div>
      ))}
    </>
  );
}

export default ServicesList;


