import React from "react";
import HeaderPage from "../../component/HeaderPage/HeaderPage";
import Project from "../../component/Project/Project";

function ProjectPage() {

    let body

    body = (
        <>
            <HeaderPage index={"1"}>

            </HeaderPage>
            <Project index={"1"}>
                
            </Project>
        </>)
    return (
        <>
            {body}
        </>
    )
}

export default ProjectPage;