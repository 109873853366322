import Newsletter from "../../component/Newsletter/Newsletter"
import FooterArea from "../../component/Footer/FooterArea";
import Copyrights from "../../component/Copyrights/Copyright";
import "../Footer/Footer.css"
function Footer() {
    return(
      <footer>
       {/* <Newsletter /> */}
        <FooterArea />
         {/* <Copyrights /> */}
      </footer >

    );
}
export default Footer;