import React from "react";
import { useLocation } from 'react-router-dom'
import { useSelector} from 'react-redux'
import { BannerSelector } from '../../store/reducers/BannerReducers'
import SkillItem from "./SkillItem"
import "../About/About.css"
import {Banner} from '../../models/reducers/IBanner'
import parse from 'html-react-parser';

interface CustomInputProps {
	children: React.ReactNode
	index: string
  }

function About({ children, index }: CustomInputProps) {
    const id_page = 'About';
	let location = useLocation();
    const ParentPage = location.pathname.replace("/", "").replace("/", "");
    const todosSelector = useSelector(BannerSelector)
  
    let todosLoading: Banner[]|undefined = todosSelector.allPageLoad
    todosLoading =  todosLoading?.filter(todo => todo.index === index && todo.id_page === id_page && todo.pathtype === ParentPage)

  return (
      <>
    {todosLoading?.map((todo, index) => {
        return (
    <section key={index} className="about-area bg-grey section-padding">
    <div className="container">
        <div className="row d-flex align-items-center">
            <div className="col-lg-6 col-md-12 col-sm-12">
            <div className="about-content">
                <div className="about-content-text">
                
                            <h6>{todo.subtitle_01}</h6> 
                            <h2>{todo.title_01}</h2>
                            <p>{parse(todo.content_01)}</p>
                        
                    <div className="skills">
                        <SkillItem ParentPage={ParentPage} index={"1"}>
                            
                        </SkillItem>
                    </div>
                    {/* <div className="about-btn-box"> 
                        <a className="default-btn project-btn-1" href={todo.link_01}>{todo.button_01}<span ></span></a>
                    </div> */}
                </div>
            </div>
            </div>
            <div className="col-lg-5 offset-lg-1 col-md-12 col-sm-12">
                <div className="about-image">
                    <img src={todo.image_url_01} alt="About image" />
                    <div className="years-design">
                        <h2>2018</h2>
                        <h5>Thành lập</h5>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </section>
    )})}
</>
  );
}

export default About;

