import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import SiteNav from "./SiteNav";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhoneVolume } from '@fortawesome/free-solid-svg-icons';
import "./Nav.css";

export default function Nav() {
  const [isSticky, setIsSticky] = useState(false)
  const [resize, setResize] = useState(window.innerWidth)
  const [show, setShow] = useState(false)

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY >= 200) {
        setIsSticky(true)
      } else {
        setIsSticky(false)
      }
    }

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    }
  }, [])

  useEffect(() => {
    const handleResize = () => {
      setResize(window.innerWidth)
    }

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    }
  }, [])
  useEffect(() => {
    if (show !== false) {
      console.log(`The name is now... ${show}!`)
    }
  }, [show])
  const MenuClick = (e: any) => {
    if (show === true) {
      // setShow(false)
      setShow(show => !show)
    } else {
      // setShow(true)
      setShow(show => !show)
    }
  }
  return (
    <>
      <div className="preloader preloader-deactivate">
        <div className="loader">
          <div className="shadow"></div>
          <div className="box"></div>
        </div>
      </div>
      <div className={`navbar-area  ${isSticky ? 'is-sticky' : ''}`}>
        <div className="techvio-responsive-nav">
          <div className="container">
            <div className="techvio-responsive-menu mean-container">
              {resize && (
                <>
                  <div id="menu-nav" className="mean-bar" >
                    <a href="#nav" className="meanmenu-reveal" onClick={MenuClick}>
                      {show ? 'X' : <><span></span><span></span><span></span></>}
                    </a>
                    {show ? (
                      <nav className={`mean-nav  ${show ? 'mean-nav-open' : ''}`}>
                        <SiteNav />
                      </nav>
                    ) : ('')}
                  </div>
                </>
              )}

              <div className="logo">
                <Link className="logo-link" to="/">
                  <img className="white-logo logo" src="/assets/Logo3-removebg-preview.png" alt="logo" />
                  <img className="black-logo logo" src="/assets/Logo3-removebg-preview.png" alt="logo" />
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="techvio-nav">
          <div className="container">
            <nav className="navbar navbar-expand-md navbar-light">
              <Link className="logo-link navbar-brand" to="/">
                <img className="white-logo logo" src="/assets/Logo3-removebg-preview.png" alt="logo" />
                <img className="black-logo logo" src="/assets/Logo3-removebg-preview.png" alt="logo" />
              </Link>
              <div className="collapse navbar-collapse mean-menu" id="navbarSupportedContent">
                <SiteNav />
                <div className="other-option">
                <a href="tel:02836220259"><FontAwesomeIcon icon={faPhoneVolume} /> 028 3622 0259</a>
                  <Link className="default-btn" to="/contact">Liên hệ<span></span></Link>
                </div>
              </div>
            </nav>
          </div>
        </div>
      </div>
    </>
  );
}

