import { configureStore } from '@reduxjs/toolkit'
import BannerReducer from './reducers/BannerReducers'
import thunkMiddleware from 'redux-thunk';
// Store
const store = configureStore({
    reducer: {
        Banner: BannerReducer
    },
    middleware: [thunkMiddleware]
});

// Export
export { store }
export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>