import { NavLink } from "react-router-dom";
import { submenus } from '../../models/layouts/Navigation/Imenu'
import React from 'react';

interface CustomInputProps {
    children: React.ReactNode
    value: submenus[]
}
const Dropdown = ({ children, value }: CustomInputProps) => {
    return (
        <ul className="dropdown-menu">
            {value ? value.map((submenu, index) => (
                <li key={index} className="nav-item">
                    <NavLink to={submenu.link} className="nav-link">{submenu.title}</NavLink>
                </li>
            )) : []}
        </ul>
    )
}

export default Dropdown;