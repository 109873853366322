import React from "react";
import HeroBanners from "../../component/Hero-banner/Hero-banner"
import Services from "../../component/Services/Service"
import Overview from "../../component/Overview/Overview"
import Counter from "../../component/Counter/Counter"
import Testimonial from "../../component/Testimonial/Testimonial"
import Team from "../../component/Team/Team"
import Brand from "../../component/Brand/Brand"

function Homepage() {

    let body
    body = (
        <>
            <HeroBanners index={"1"}>

            </HeroBanners>
            <Services index={"1"}>

            </Services>
            <Overview index={"1"}>

            </Overview>
            <Counter index={"1"}>

            </Counter>
            {/* <Testimonial index={"1"}>

            </Testimonial> */}
            {/* <Team index={"1"}>

            </Team>
            <HeroBanners index={"2"}>

            </HeroBanners> */}
            {/* <Brand index={"1"}>

            </Brand> */}
        </>
    )

    return (<>
        {body}
    </>
    )
}

export default Homepage;