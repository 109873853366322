import React from "react";
import { useSelector } from 'react-redux'
import { BannerSelector } from '../../store/reducers/BannerReducers'
import {Banner} from '../../models/reducers/IBanner'

interface CustomInputProps {
  children: React.ReactNode
  index: string
  ParentPage: string
}

function CounterList({ children, index , ParentPage }: CustomInputProps) {
    const id_page = 'CounterList';
    const todosSelector = useSelector(BannerSelector)

    let todosLoading: Banner[]|undefined = todosSelector.allPageLoad
    todosLoading = todosLoading?.filter(todo => todo.index === index && todo.id_page === id_page && todo.pathtype === ParentPage)

    return (
        <>
            {todosLoading?.map((todo, index) => {
                return (
                    <div key={index} className="col-lg-3 col-md-6 counter-item">
                        <div className="single-counter">
                            <div className="counter-contents">
                                <h2>
                                    <span className="counter-number">{todo.subtitle_01}</span>
                                    <span>+</span>
                                </h2>
                                <h3 className="counter-heading">{todo.title_01}</h3>
                            </div>
                        </div>
                    </div>
                );
            })}
        </>
    );
}

export default CounterList;



