import HeaderPage from "../../component/HeaderPage/HeaderPage";
import Blog from "../../component/Blog/Blog";


function BlogPage() {
    let body
    body = (
        <>
            <HeaderPage index={"1"}>

            </HeaderPage>
            <Blog index={"1"}>

            </Blog>
        </>)
    return (
        <>
            {body}
        </>
    )
}

export default BlogPage;