import React from "react";
import { useSelector } from 'react-redux'
import { BannerSelector } from '../../store/reducers/BannerReducers'
import {Banner} from '../../models/reducers/IBanner'
import parse from 'html-react-parser';

interface CustomInputProps {
  children: React.ReactNode
  index: string
  ParentPage: string
}

function SkillItem({ children, index , ParentPage }: CustomInputProps) {
  const id_page = 'SkillItem';
  const todosSelector = useSelector(BannerSelector)

  let todosLoading: Banner[]|undefined = todosSelector.allPageLoad
  todosLoading = todosLoading?.filter(todo => todo.index === index && todo.id_page === id_page && todo.pathtype === ParentPage)

  return (
    <div>
      {todosLoading?.map((todo, index) => {
        return (
          <div key={index} className="skill-item">
            <h6>{parse(todo.title_01)} </h6> 
            {/* <em>{todo.title_02}</em> */}
            <div className="skill-progress">
              <div className="progres" data-value={todo.title_02} style={{ width: `${todo.title_02}` }}></div>
            </div>
          </div>
        );
      })}
    </div>
  );
}

export default SkillItem;

