import React from "react";
import HeaderPage from "../../component/HeaderPage/HeaderPage";
import ContactInformation from "../../component/Contact/ContactInformation"
import ContactUs from "../../component/Contact/ContactUs"
import Map from "../../component/Contact/Map"


function ContactPage() {

    let body

    body = (
        <>
            <HeaderPage index={"1"}>

            </HeaderPage>
            <ContactInformation index={"1"}>

            </ContactInformation>
            <ContactUs index={"1"}>

            </ContactUs>
            <Map index={"1"}>

            </Map>
        </>
    )
    return (
        <>
            {body}
        </>
    )
}

export default ContactPage;