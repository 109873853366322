import React from "react";
import { useSelector } from 'react-redux'
import { BannerSelector } from '../../store/reducers/BannerReducers'
import "../Hero-banner/Hero-banner.css"
import { useLocation } from 'react-router-dom'
import { Banner } from '../../models/reducers/IBanner'

interface CustomInputProps {
    children: React.ReactNode
    index: string
}
function HeroBanners({ children, index }: CustomInputProps) {
    const id_page = 'HeroBanners';

    let location = useLocation();
    const ParentPage = location.pathname.replace("/", "").replace("/", "");
    const todosSelector = useSelector(BannerSelector)
    let todosLoading: Banner[] | undefined = todosSelector.allPageLoad
    todosLoading = todosLoading?.filter(todo => todo.index === index && todo.id_page === id_page && todo.pathtype === ParentPage)
    return (
        <>
            {todosLoading?.map(todo => (
                <div key={index} className={`home-area ${todo.classname_01}`} style={todo.classname_01 === 'style_2' ? { backgroundImage: `url(${todo.background})` } : {}}>
                    <div className="d-table">
                        <div className="d-table-cell">
                            <div className="container">
                                <div className="row align-items-center">
                                    <div className="col-lg-6 col-md-12">
                                        <div className="main-banner-content">
                                            <h6>{todo.subtitle_01}</h6>
                                            <h1>{todo.title_01}</h1>
                                            <p>{todo.content_01}</p>
                                            {/* <div className="banner-btn">
                                                <a href={todo.link_01} className="default-btn-one">
                                                    {todo.button_01}
                                                    <span></span>
                                                </a>
                                                <a className="default-btn" href={todo.link_02}>
                                                    {todo.button_02}
                                                    <span></span>
                                                </a>
                                            </div> */}
                                        </div>
                                    </div>
                                    {todo.classname_01 === 'style_1' ? (
                                        <div className="col-lg-6 col-md-12">
                                            <div className="banner-image">
                                                {todo.image_url_01 != "" ? (<img src={todo.image_url_01} alt="image" />) : (<></>)}
                                            </div>
                                        </div>) : ('')}

                                </div>
                            </div>
                        </div>
                    </div>
                    {todo.classname_01 == 'style_1' ? (
                        <div className="creative-shape">
                            {
                                todo.video_url_01 && todo.video_url_01 !== "" ?
                                    (
                                        <>
                                        {/* 1903  800 */}
                                            <video id="myVideo" width="100%" height="100%" loop={true} muted={true} data-autoplay="" playsInline={true} autoPlay={true}>
                                                <source src={todo.video_url_01} type="video/mp4" />
                                            </video>
                                        </>
                                    ) :
                                    (
                                        todo.image_url_02 != "" ? (<img src={todo.image_url_02} alt="image" />) : (<></>)
                                    )

                            }
                            {/* {todo.image_url_02 != "" ? (<img src={todo.image_url_02} alt="image" />) : (<></>)} */}
                        </div>) : ('')}
                </div>
            ))}
        </>
    );



}
export default HeroBanners

