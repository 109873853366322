import React from "react";
import { useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { BannerSelector } from '../../store/reducers/BannerReducers'
import ContactInformationList from "./ContactInformationList"
import "../Contact/ContactInformation.css"
import { Banner } from '../../models/reducers/IBanner'

interface CustomInputProps {
  children: React.ReactNode
  index: string
}
function ContactInformation({ children, index }: CustomInputProps) {
    const id_page = 'ContactInformation';
    let location = useLocation();
    const ParentPage = location.pathname.replace("/", "").replace("/", "");
    const todosSelector = useSelector(BannerSelector)

    let todosLoading: Banner[]|undefined = todosSelector.allPageLoad
    todosLoading = todosLoading?.filter(todo => todo.index === index && todo.id_page === id_page && todo.pathtype === ParentPage)


    return (
        <>
            <section className="contact-info-wrapper">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="section-title">
                                {todosLoading?.map((todo, index) => {
                                    return (
                                        <div key={index}>
                                            <h6>{todo.title_01}</h6>
                                            <h2>{todo.subtitle_01}</h2>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                        <ContactInformationList ParentPage={ParentPage} index={"1"}>
                            
                        </ContactInformationList>

                    </div>
                </div>
            </section>

        </>
    );
}

export default ContactInformation;