import { useLocation } from 'react-router-dom'
import { Routes, Route } from "react-router-dom";
import React, { useLayoutEffect } from 'react';
import Nav from "../Navigation/Nav"
import Footer from "../Footer/Footer"
import Homepage from '../../pages/Home/Home'
import About from '../../pages/About/About'
import Services from '../../pages/Services/Services'
import ServicesDetail from '../../pages/Services/ServicesDetail'
import Project from '../../pages/Projects/Projects'
import Blog from '../../pages/Blog/Blogs'
import Contact from '../../pages/Contact/Contact'
import ServicesDetailPage_2 from '../../pages/Services/service_2'
import ProjectDetailPage from '../../pages/Projects/ProjectDetail'


const Layout = () => {
    {
        let body
        let location = useLocation();
        const id_page = location.pathname.replace("/", "").replace("/", "");
        useLayoutEffect(() => {
            window.scrollTo(0, 0);
          }, [location.pathname]);
        body = (
            <>
                <Nav />
                <main>
                    <Routes>
                        <Route path="/" element={<Homepage />}></Route>
                        <Route path="/about" element={<About />}></Route>
                        <Route path="/services" element={<Services />}></Route>
                        <Route path="/services-detail" element={<ServicesDetail />}></Route>
                        <Route path="/services-detail-2" element={<ServicesDetail />}></Route>
                        <Route path="/project-detail" element={<ProjectDetailPage />}></Route>
                        <Route path="/project" element={<Project />}></Route>
                        <Route path="/blog" element={<Blog />}></Route>
                        <Route path="/contact" element={<Contact />}></Route> 
                    </Routes>
                </main>
                <Footer />
            </>
        )
        return (
            <>
                {body}
            </>);
    }
}
export default Layout;  