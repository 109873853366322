import React from "react";
import { useSelector } from 'react-redux'
import { BannerSelector } from '../../store/reducers/BannerReducers'

interface CustomInputProps {
    children: React.ReactNode
    index: string
    ParentPage: string
  }
function ServicesDetailList({ children, index , ParentPage}: CustomInputProps) {

    const id_page = 'ServicesDetailList';
    const todosSelector = useSelector(BannerSelector)

    let todosLoading = todosSelector.allPageLoad
    todosLoading = todosLoading?.filter(todo => todo.index === index && todo.id_page === id_page && todo.pathtype === ParentPage)

    return (
        <>
            {todosLoading?.map((todo, index) => {
                return (
                    <div key={index} className="col-lg-6 col-md-12">
                        <div className="services-step-content">
                            <p>{todo.subtitle_01}</p>
                            <div className="features-text">
                                <h4>{todo.title_01}</h4>
                                <p>{todo.content_01}</p>
                            </div>
                            <div className="features-text">
                                <h4>{todo.title_02}</h4>
                                <p>{todo.content_02}</p>
                            </div>
                        </div>
                    </div>
                    
                );
            })}
        </>
    );
}

export default ServicesDetailList;