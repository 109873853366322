import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import 'firebase/compat/firestore'
import 'firebase/compat/storage'

// const firebaseConfig = {
//     apiKey: "AIzaSyDEEAcvy8A7J-o2ezX91JUHmYYj-J0Dz-A",
//     authDomain: "https://sales-app-3091c.firebaseapp.com", 
//     databaseURL: "https://sales-app-3091c.firebaseio.com",
//     projectId: "sales-app-3091c",
//     storageBucket: "sales-app-3091c.appspot.com",
//     messagingSenderId: "136544643348",
//     appId: "1:136544643348:android:bf4e53f3e11970ca22fa01"
// };
const firebaseConfig = {
    apiKey: "AIzaSyDi7-hSFv2m1qoEsiOLI3ulOZNgJ-60oSE",
    authDomain: "daiduonginfo-1b710.firebaseapp.com",
    projectId: "daiduonginfo-1b710",
    storageBucket: "daiduonginfo-1b710.appspot.com",
    messagingSenderId: "769450842050",
    appId: "1:769450842050:web:2e21c06a8d8ebe2b25685b",
    measurementId: "G-97ZYF9LJFG"
};
if (!firebase.apps.length) firebase.initializeApp(firebaseConfig)
    // const auth = firebase.auth()
const db = firebase.firestore()
    // const storage = firebase.storage()
export default db


// const firebaseConfig = {
//     apiKey: "AIzaSyDF5cbzzNqCCgwpCWkluO6d9jWGQEs3nZg", 
//     authDomain: "nhakhoasaigon-747a0.firebaseapp.com", 
//     projectId: "nhakhoasaigon-747a0",
//     storageBucket: "nhakhoasaigon-747a0.appspot.com",
//     messagingSenderId: "1076736641763",
//     appId: "1:1076736641763:web:a81bcef0963b7b7c3453d6",
//     measurementId: "G-71EMF2GX7L"
// };


// const firebaseConfig = {
//     apiKey: "AIzaSyDEEAcvy8A7J-o2ezX91JUHmYYj-J0Dz-A",
//     authDomain: "nhakhoasaigon-747a0.firebaseapp.com",
//     projectId: "sales-app-3091c",
//     storageBucket: "sales-app-3091c.appspot.com",
//     messagingSenderId: "1076736641763",
//     appId: "1:136544643348:android:bf4e53f3e11970ca22fa01",
//     measurementId: "G-71EMF2GX7L"
// };