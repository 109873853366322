import React, {useEffect, useRef} from "react";

import emailjs from 'emailjs-com';
import Swal from 'sweetalert2';
import "../Contact/ContactUs.css"

interface CustomInputProps {
	children: React.ReactNode
	index: string
  }

function ContentUs({ children, index }: CustomInputProps) {

    const form= React.useRef<HTMLFormElement>(null)
    // const [information, setInformation] = useState('')
    const sendEmail = (e: any) => {
        e.preventDefault();

        emailjs.sendForm('service_4car6mo', 'template_ijb6lbl', e.target, 'jmexftuefBRELZC4m')
            .then((result) => {
                console.log(result.text);
                Swal.fire({
                    icon: 'success',
                    title: 'Message Sent Successfully'
                  })
            }, (error) => {
                console.log(error.text);
                Swal.fire({
                    icon: 'error',
                    title: 'Ooops, something went wrong',
                    text: error.text,
                  })
            });
    };
  return (
	  <>
	<div key={index} className="contact-section bg-grey section-padding">
		<div className="container">
			<div className="section-title">
				<h6>Liên Hệ</h6>
				<h2>LIÊN HỆ VỚI CHÚNG TÔI</h2>
			</div>
			<div className="row align-items-center">
				<div className="col-lg-10 offset-lg-1">
					<div className="contact-form">
						<p className="form-message"></p>
						<form ref={form} onSubmit={sendEmail}>
							<div className="row">
								<div className="col-lg-6 col-md-6">
									<div className="form-group">
										<input type="text" name="name" id="name" className="form-control" required placeholder="Tên của bạn" />
									</div>
								</div>
								<div className="col-lg-6 col-md-6">
									<div className="form-group">
										<input type="email" name="email" id="email" className="form-control" required placeholder="Email của bạn" />
									</div>
								</div>
								<div className="col-lg-6 col-md-6">
									<div className="form-group">
										<input type="text" name="phone" id="phone" required className="form-control" placeholder="Số điện thoại" />
									</div>
								</div>
								<div className="col-lg-6 col-md-6">
									<div className="form-group">
										<input type="text" name="subject" id="subject" className="form-control" required placeholder="Tiêu đề" />
									</div>
								</div>
								<div className="col-lg-12 col-md-12">
									<div className="form-group">
										<textarea name="message" className="form-control" id="message" cols={30} rows={6} required placeholder="Nội dung"></textarea>
									</div>
								</div>
								<div className="col-lg-12 col-md-12">
									<button type="submit" className="default-btn submit-btn">Gửi Email<span></span></button>
								</div>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	</div>
	</>
  );
}

export default ContentUs;