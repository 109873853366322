import React from "react";
import { useSelector } from 'react-redux'
import { BannerSelector } from '../../store/reducers/BannerReducers'
import TestimonialList from "./TestimonialList"
import "./Testimonial.css"
import { useLocation } from 'react-router-dom'
import { Banner } from '../../models/reducers/IBanner'

interface CustomInputProps {
  children: React.ReactNode
  index: string
}
function Testimonial({ children, index }: CustomInputProps) {
    const id_page = 'Testimonial'; 
    let location = useLocation();
    const ParentPage = location.pathname.replace("/", "").replace("/", "");
    const todosSelector = useSelector(BannerSelector)
  
    let todosLoading: Banner[]|undefined = todosSelector.allPageLoad
    todosLoading = todosLoading?.filter(todo => todo.index === index && todo.id_page === id_page && todo.pathtype === ParentPage)

  return (
    <section className="testimonial-section pt-100 pb-50">
    <div className="container">
    {todosLoading?.map((todo, index) => {
        return (
        <div key={index} className="section-title">
            <h6>{todo.title_01}</h6>
            <h2>{todo.subtitle_01}</h2>
        </div>
        )})}
        <div className="row">
            <div className="col-lg-12 col-md-12">
                <div className="testimonial-slider owl-carousel owl-theme owl-loaded owl-drag">   
                    <TestimonialList ParentPage={ParentPage} index={"1"}>
                        
                    </TestimonialList>
                </div>
            </div>
        </div>
    </div>
</section>
  );
}

export default Testimonial;

