import React from "react";
import { useSelector } from 'react-redux'
import { BannerSelector } from '../../store/reducers/BannerReducers'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTwitter,faFacebookF, faLinkedin } from '@fortawesome/free-brands-svg-icons'
import {Banner} from '../../models/reducers/IBanner'

interface CustomInputProps {
  children: React.ReactNode
  index: string
  ParentPage: string
}
function TeamList({ children, index , ParentPage }: CustomInputProps) {
	const id_page = 'TeamList'; 
    const todosSelector = useSelector(BannerSelector)

    let todosLoading: Banner[]|undefined = todosSelector.allPageLoad
    todosLoading = todosLoading?.filter(todo => todo.index === index && todo.id_page === id_page && todo.pathtype === ParentPage)


  return (
   <>
    {todosLoading?.map((todo, index) => {
        return (
            <div key={index}  className="col-lg-3 col-md-6 team-item">
            <div className="single-team-box">
                <div className="team-image">
                    <img src={todo.image_url_01} alt={todo.title_01}/>
                    <div className="team-social-icon">
                        <a href="https://www.facebook.com/profile.php?id=100035429103189" className="social-color-1"><FontAwesomeIcon icon={faFacebookF} /></a>
                        <a href="#" className="social-color-2"><FontAwesomeIcon icon={faTwitter} /></a>
                        <a href="#" className="social-color-3"><FontAwesomeIcon icon={faLinkedin} /></a>
                    </div>
                </div>
                <div className="team-info">
                <h3>{todo.title_01}</h3>
                <span>{todo.subtitle_01}</span>
            </div>
        </div>
        </div>
        );
    })}
    </>
  );
}

export default TeamList;
