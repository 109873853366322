import React from "react";
import HeaderPage from "../../component/HeaderPage/HeaderPage";
import Testimonial from "../../component/Testimonial/Testimonial"

function ProjectDetailPage() {

    let body

    body = (
        <>
            <HeaderPage index={"1"}>

            </HeaderPage>
            <Testimonial index={"1"}>

            </Testimonial>
        </>)
    return (
        <>
            {body}
        </>
    )
}

export default ProjectDetailPage;