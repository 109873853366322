import React from "react";
import { useSelector } from 'react-redux'
import { BannerSelector } from '../../store/reducers/BannerReducers'
import Slider from 'react-slick';

import parse from 'html-react-parser';
import {Banner} from '../../models/reducers/IBanner'

interface CustomInputProps {
  children: React.ReactNode
  index: string
  ParentPage: string
}
function TestimonialList({ children, index , ParentPage }: CustomInputProps) {
    const id_page = 'TestimonialList'; 
    const todosSelector = useSelector(BannerSelector)

    let todosLoading: Banner[]|undefined = todosSelector.allPageLoad
    todosLoading = todosLoading?.filter(todo => todo.index === index && todo.id_page === id_page && todo.pathtype === ParentPage)

    const settings = {
        className: 'slider-member',
        slidesToShow: 3,
        slidesToScroll: 1,
        dots: false,
        infinite: true,
        speed: 500,
        autoplay:true,
        responsive: [{
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }]
      };
  return (
    <>
    <Slider {...settings}>
    {todosLoading?.map((todo, index) => {
        return (
            <div key={index} className="col-lg-4 col-md-6 testimonia-item cloned">
                <div className="single-testimonial">
                    <div className="rating-box">
                        <ul>
                            <li><i className="fa fa-star"></i></li>
                            <li><i className="fa fa-star"></i></li>
                            <li><i className="fa fa-star"></i></li>
                            <li><i className="fa fa-star"></i></li>
                            <li><i className="fa fa-star"></i></li>
                        </ul>
                    </div>
                    <div className="testimonial-content">
                        <p>{parse(todo.content_01)}</p>
                    </div>
                    <div className="avatar">
                    <img src={todo.image_url_01} alt={todo.title_01}/>
                    </div>
                    <div className="testimonial-bio">
                        <div className="bio-info">
                            <h3>{parse(todo.title_01)}</h3>
                            <span>{parse(todo.subtitle_01)}</span>
                        </div>
                    </div>
                </div>
            </div>
        );
    })}
    </Slider>
    </>
  );
}

export default TestimonialList;


