import React from "react";
import { useSelector } from 'react-redux'
import { BannerSelector } from '../../store/reducers/BannerReducers'
import TeamList from "./TeamList"
import "../Team/Team.css"
import { useLocation } from 'react-router-dom'
import { Banner } from '../../models/reducers/IBanner'

interface CustomInputProps {
	children: React.ReactNode
	index: string
  }
function Team({ children, index }: CustomInputProps) {
	const id_page = 'Team'; 
	let location = useLocation();
    const ParentPage = location.pathname.replace("/", "").replace("/", "");
    const todosSelector = useSelector(BannerSelector)
  
    let todosLoading: Banner[]|undefined = todosSelector.allPageLoad
    todosLoading = todosLoading?.filter(todo => todo.index === index && todo.id_page === id_page && todo.pathtype === ParentPage)

  return (
    <section className="team-area section-padding">
		<div className="container">
			<div className="row">
				<div className="col-md-12">
				{todosLoading?.map((todo, index) => {
        		return (
					<div key={index}className="section-title">
						<h6>{todo.title_01}</h6>
						<h2>{todo.subtitle_01}</h2>
					</div>
				)})}
				</div>
                <TeamList ParentPage={ParentPage} index={"1"}>

				</TeamList>
			</div>
		</div>
	</section>
  );
}

export default Team;