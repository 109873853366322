import React from "react";
import { useSelector } from 'react-redux'
import { BannerSelector } from '../../store/reducers/BannerReducers'
import ServicesDetailListImg from "./ServicesDetailImg"
import ServicesDetailList from "../../component/Services/ServicesDetailList"
import "../Services/ServicesDetai.css"
import { useLocation } from 'react-router-dom'
import { Banner } from '../../models/reducers/IBanner'

interface CustomInputProps {
	children: React.ReactNode
	index: string
  }

function ServicesDetail({ children, index }: CustomInputProps) {
    const id_page = 'ServicesDetail';
    let location = useLocation();
	const ParentPage = location.pathname.replace("/", "").replace("/", "");
	const todosSelector = useSelector(BannerSelector)
	let todosLoading: Banner[]|undefined = todosSelector.allPageLoad

	todosLoading = todosLoading?.filter(todo => todo.index === index && todo.id_page === id_page && todo.pathtype === ParentPage)

    // Display the result on the page
    return (
        <>
            <div >
                {todosLoading?.map((todo, index_key) => (
                    <div key={index_key} >
                        <div className="col-lg-12 col-md-12">
                            <ServicesDetailListImg ParentPage={ParentPage} index={index} >
                                
                            </ServicesDetailListImg>
                        </div>
                        <div className="col-lg-12 col-md-12">
                            <div className="services-step-wrapper">
                                <div className="services-step-title">
                                    <h2>{todo.title_01}</h2>
                                </div>
                                <div className="row">
                                    <ServicesDetailList ParentPage={ParentPage} index={index}>

                                    </ServicesDetailList>
                                </div>
                            </div>
                        </div>
                    </div>
                ))
                }
            </div>
        </>

    );
}

export default ServicesDetail;
