import React from "react";
import { useSelector } from 'react-redux'
import { BannerSelector } from '../../store/reducers/BannerReducers'
import FeaturesList from "./FeaturesList"
import "../Overview/Overview.css"
import { useLocation } from 'react-router-dom'
import { Banner } from '../../models/reducers/IBanner'
import parse from 'html-react-parser';

interface CustomInputProps {
    children: React.ReactNode
    index: string
}

function Overview({ children, index }: CustomInputProps) {
    const id_page = 'Overview';
    let location = useLocation();
    const ParentPage = location.pathname.replace("/", "").replace("/", "");
    const todosSelector = useSelector(BannerSelector)

    let todosLoading: Banner[] | undefined = todosSelector.allPageLoad
    todosLoading = todosLoading?.filter(todo => todo.index === index && todo.id_page === id_page && todo.pathtype === ParentPage)

    let body

    body = (
        <>
            {todosLoading?.map(todo => (
                <section key={todo.index} className={`overview-section section-padding ${todo.classname_01}`}>
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-6">
                                <div className="overview-image">
                                    {todo.video_url_01 && todo.video_url_01 !== "" ? (
                                        <>
                                            <video id="myVideo" width="670" height="466" loop={true} muted={true} data-autoplay="" playsInline={true} autoPlay={true}>
                                                <source src={todo.video_url_01} type="video/mp4" />
                                            </video>
                                        </>
                                    ):<img src={todo.image_url_01} alt={todo.title_01} />}

                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="overview-content">
                                    <h6>{todo.title_01}</h6>
                                    <h2>{todo.subtitle_01}</h2>
                                    <p>{parse(todo.content_01)}</p>
                                    <FeaturesList ParentPage={ParentPage} index={index}>

                                    </FeaturesList>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            ))}
        </>
    )

    return (
        <>
            {body}
        </>
    );
}

export default Overview;