import './App.css';
import Layout from './layouts/Content/Layout'
import { BannerSelector, getBanner } from './store/reducers/BannerReducers'
import { useEffect } from "react";
import Loading from './component/Loading/Loading'
import { useSelector, useDispatch } from 'react-redux'

function App() {

  const dispatch = useDispatch()
  const BannerSelectors = useSelector(BannerSelector)
  useEffect(() => {
    dispatch<any>(getBanner())
  }, [])

  let body
  if (BannerSelectors.isLoading) {
    body = (
      <div className='Loading'>
        <Loading></Loading>
      </div> 

    )
  }
  else {
    body = (
      <>
        <Layout />
      </>

    )
  }
  return (
    <>
      {body}

    </>
  );
}

export default App;
