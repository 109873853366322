import React from "react";
import HeaderPage from "../../component/HeaderPage/HeaderPage";
import Services from "../../component/Services/Service";
import Overview from "../../component/Overview/Overview";
import HeroBanners from "../../component/Hero-banner/Hero-banner";



function ServicesPage() {


    let body
    
        body = (
            <>
                <HeaderPage index={"1"}>

                </HeaderPage>
                {/* <Services index={"1"}>

                </Services> */}
                {/* <HeroBanners index={"1"}>

                </HeroBanners> */}
                <Overview index={"1"}>

                </Overview>
                <Overview index={"2"}>
                    
                </Overview>
            </>
        )
    return (
        <>
            {body}
        </>
    )
}

export default ServicesPage;