import React from "react";
import CounterList from "./CounterList"
import "../Counter/Counter.css"
import { useLocation } from 'react-router-dom'

interface CustomInputProps {
  children: React.ReactNode
  index: string
}
function Counter({ children, index }: CustomInputProps) {
  const id_page = 'Counter';
  let location = useLocation();
  const ParentPage = location.pathname.replace("/", "").replace("/", "");

  return (
    <section className="counter-area section-padding">
      <div className="container">
        <div className="row">
          <CounterList ParentPage={ParentPage} index={"1"}>

          </CounterList>
        </div>
      </div>
    </section>
  );
}

export default Counter;
