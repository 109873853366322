import { AnyMxRecord } from "dns";
import React from "react";
import { Link } from "react-router-dom";
import { FooterItem } from '../../models/layouts/Footer/IFooterItem'

interface CustomInputProps {
  children: React.ReactNode
  value: number
}
function FooterLink({ children, value }: CustomInputProps) {

  let FoorterLink: FooterItem[]
  if (value === 1) {
    FoorterLink = [{
      title: "Nhà máy Thuận An",
      link: "/"
    },
    {
      title: "Nhà máy Tân Uyên",
      link: "/"
    },
    {
      title: "Nhà máy Kontum",
      link: "/"
    },
    {
      title: "Nhà máy Thanh Hóa",
      link: "/"
    },
    {
      title: "Nhà máy Bình Định",
      link: "/"
    }];
  }
  else {
    FoorterLink = [{
      title: "Giới thiệu",
      link: "/about"
    },
    {
      title: "Liên hệ",
      link: "/contact"
    },
    {
      title: "Privacy Policy",
      link: "#"
    },
    {
      title: "Terms & Conditions",
      link: "#"
    }];

  }


  return (
    <>
      {FoorterLink.map((FooterLink, index) => {
        return (
          <li key={index}> <Link to={FooterLink.link}>{FooterLink.title}</Link></li>
        );
      })}
    </>
  );
}

export default FooterLink;
